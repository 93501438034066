//** Import Assets */
import ReactPlayer from "react-player";
import "../../assets/css/Homepage.css";
import logo from "../../assets/images/bizzll-logo.png";

//** Import Components */
// import BizzllLiveTv from "../../components/common/BizzllLiveTv";
// import BizzllVideoPlayer from "../../components/common/BizzllVideoPlayer";
import HomepageFeaturedSlider from "./HomepageFeaturedSlider";
import HomepageHero from "./HomepageHero";
// import HomepagePackages from "./HomepagePackages";

export default function Homepage(props) {
  return (
    <div id="bizzll-home">
      <HomepageHero />

      <section id="why-bizzll">
        <h2 className="heading-separator">
          Grow your business with <img src={logo} alt="" />
        </h2>
        <HomepageFeaturedSlider />
      </section>

      <div className="page-content">
        <section id="live-tv">
          <div className="container">
            <iframe
              width="1150px"
              height="650.25px"
              src="https://www.youtube.com/embed/Rtk8-rE3UqI?rel=0"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              title="Welcome To BIZZLL"
            ></iframe>
          </div>
        </section>

        {/* <section id="packages">
          <div className="container">
            <HomepagePackages />
          </div>
        </section> */}
      </div>
    </div>
  );
}
