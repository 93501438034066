import React, { useState, useEffect, useContext } from "react";
import { Button, Empty, Skeleton } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axiosWithAuth from "../../../utils/axiosWithAuth";
import SingleAdvertisement from "./SingleAdvertisement";
import CreateAdModal from "./CreateAdModal";
import axios from "axios";
import { SocketContext } from "../../../contexts/SocketIO";
import axiosGeneral from "../../../utils/axiosGeneral";
import { getCookie } from "../../../common/manageCookies";

const Advertisements = (props) => {
  const { business, user, propsData } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [advertisementList, setAdvertisementList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const socket = useContext(SocketContext);

  // useEffect(() => {
  //   const APIURL = `/api/activity/getAllAdvertisement/${business.id}`;

  //   axiosGeneral()
  //     .get(APIURL)
  //     .then((res) => {
  //       const sortedAdvertisements = res.data.sort(function (a, b) {
  //         return new Date(b.created) - new Date(a.created);
  //       });
  //       setAdvertisementList(sortedAdvertisements);
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setIsLoading(false);
  //     });
  // }, []);

useEffect(() => {
  fetchAdvertisements();
}, []);

// Función para cargar los anuncios
const fetchAdvertisements = () => {
  const APIURL = `/api/activity/getAllAdvertisement/${business.id}`;
  setIsLoading(true);

  axiosGeneral()
    .get(APIURL)
    .then((res) => {
      const sortedAdvertisements = res.data.sort(function (a, b) {
        return new Date(b.created) - new Date(a.created);
      });
      setAdvertisementList(sortedAdvertisements);
      setIsLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setIsLoading(false);
    });
};

  const createAdvertisement = async ({ formData, values }) => {
    setIsSubmitting(true);

    const APIURL = `/api/activity/create`;
    try {
      const res = await axiosWithAuth().post(APIURL, {
        text: values.text,
        business_id: business.id,
      });
      // const token = localStorage.getItem("token");
      const token = getCookie("ubt");

      console.log("token")
      console.log(token)

      // console.log("JSON.parse(token)")
      // console.log(JSON.parse(token))

      // const config = {
      //   headers: {
      //     Authorization: `Bearer ${JSON.parse(token).token}`,
      //     "content-type": `multipart/form-data`,
      //   },
      // };
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": `multipart/form-data`,
        },
      };
      if (values.attachments) {
        console.log("paso-1")
        const imageRes = await axios.post(
          `${process.env.REACT_APP_BE_API_URL}/api/activity/uploadImage/businesses-${business.id}-${res.data.id}`,
          formData,
          config
        );
        console.log("paso-2")
        await axiosWithAuth().put(`/api/activity/update/${res.data.id}`, {
          data: { attachments: imageRes.data.url, firstUpdate: true },
        });
        console.log("paso-3")
        res.data.attachments = imageRes.data.url;
      }
      setIsSubmitting(false);
      setShowModal(false);
      
      console.log("res info de rest")
      console.log(res)
      if (res.status == 201) {
        // setShouldUpdate(!shouldUpdate);
        setAdvertisementList([res.data, ...advertisementList]);

        // Cargar la lista actualizada de anuncios
        console.log("actualizar lista despues de actualizar")
        fetchAdvertisements();
      }
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  };

  const deleteAdvertisement = async (id) => {
    const APIURL = `/api/activity/delete/${id}`;
    try {
      const res = await axiosWithAuth().delete(APIURL);
      if (res.status == 200) {
        // setShouldUpdate(!shouldUpdate);
        const filteredList = advertisementList.filter((ad) => ad.id != id);
        setAdvertisementList(filteredList);
      }
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  };

  const updateAdvertisement = async (id, data) => {
    const APIURL = `/api/activity/update/${id}`;
    try {
      console.log("Metodo-modificar-POST")
      if (data.values.attachments && data.values.attachments.preview) {
        // const token = localStorage.getItem("token");
        const token = getCookie("ubt");
        console.log("PASO-1")
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": `multipart/form-data`,
          },
        };
        console.log("PASO-2")
        const imageUrl = await axios.post(
          `${process.env.REACT_APP_BE_API_URL}/api/activity/uploadImage/businesses-${business.id}-${id}`,
          data.formData,
          config
        );
        console.log("PASO-3")
        data.values.attachments = imageUrl.data.url;
        data.values.shouldDeleteImage = true;
        console.log("PASO-4")
      }

      delete data.values.user_id;
      console.log("PASO-5")
      console.log("DataReq")
      console.log(data.values)
      const res = await axiosWithAuth().put(APIURL, { data: data.values });
      console.log("PASO-6")
      if (res.status == 202) {
        // setShouldUpdate(!shouldUpdate);
        const newAdvertisementList = advertisementList.map((ad) => {
          if (ad.id == id) {
            return res.data.data;
          }
          return ad;
        });
        setAdvertisementList(newAdvertisementList);
      }
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  };

  const likeAdvertisement = async (id) => {
    const APIURL = `/api/activity/likeAdvertisement`;
    const res = await axiosWithAuth().post(APIURL, {
      advertisementId: id,
      businessId: business.id,
      owner_id: business.owner_id,
    });
    if (res.status == 201) {
      // setLiked(true);
      // setShouldUpdate(!shouldUpdate);
      const newAdvertisementList = advertisementList.map((ad) => {
        if (ad.id == id) {
          return res.data.data;
        }
        return ad;
      });
      setAdvertisementList(newAdvertisementList);
      if (business.owner_id != user.id && res.data.shouldNotify) {
        socket.emit("send-notification", business, "like");
      }
    }
  };

  const unlikeAdvertisement = async (id) => {
    const APIURL = `/api/activity/unlikeAdvertisement`;
    const res = await axiosWithAuth().post(APIURL, {
      advertisementId: id,
    });
    if (res.status == 201) {
      // setLiked(false);
      // setShouldUpdate(!shouldUpdate);
      const newAdvertisementList = advertisementList.map((ad) => {
        if (ad.id == id) {
          return res.data;
        }
        return ad;
      });
      setAdvertisementList(newAdvertisementList);
    }
  };

  const commentAdvertisement = async (id, data) => {
    const APIURL = `/api/activity/commentAdvertisement`;
    const res = await axiosWithAuth().post(APIURL, {
      advertisementId: id,
      text: data.text,
      businessId: business.id,
      owner_id: business.owner_id,
    });
    if (res.status == 201) {
      const newAdvertisementList = advertisementList.map((ad) => {
        if (ad.id == id) {
          return res.data.data;
        }
        return ad;
      });
      setAdvertisementList(newAdvertisementList);
      if (business.owner_id != user.id) {
        socket.emit("send-notification", business, "comment");
      }
      // setCommented(!commented);
    }
  };

  const shareAdvertisement = async (id, text) => {
    const APIURL = `/api/activity/shareAdvertisement`;
    const res = await axiosWithAuth().post(APIURL, {
      advertisementId: id,
      businessId: business.id,
      text,
    });
    if (res.status === 200) {
      const newAdvertisementList = advertisementList.map((ad) => {
        if (ad.id === id) {
          return res.data.data;
        }
        return ad;
      });
      setAdvertisementList(newAdvertisementList);
      // if (business.owner_id != user.id) {
      //   socket.emit("send-notification", business, "comment");
      // }
    }
  };

  const deleteComment = async (id, activity_wall_id, sender_id) => {
    const APIURL = `/api/activity/deleteComment`;
    const res = await axiosWithAuth().post(APIURL, {
      id,
      activity_wall_id,
      sender_id,
    });
    if (res.status == 202) {
      const newList = advertisementList.map((ad) => {
        if (ad.id == activity_wall_id) {
          ad.commentData = ad.commentData.filter((comment) => comment.id != id);
        }
        return ad;
      });
      setAdvertisementList(newList);
    }
  };

  return (
    <div>
      <div className='post-content'>
        {user.id == business.owner_id && (
          <div>
            <Button
              className='add-post-btn btn-right'
              onClick={() => setShowModal(true)}
            >
              + Post
            </Button>
          </div>
        )}

        <CreateAdModal
          showModal={showModal}
          setShowModal={setShowModal}
          handleSubmit={createAdvertisement}
          isSubmitting={isSubmitting}
        />
        {isLoading ? (
          <Skeleton active className='pre-content' />
        ) : advertisementList.length > 0 ? (
          <div className='all-advertisements'>
            {advertisementList.map((advertisement) => (
              <SingleAdvertisement
                key={advertisement.id}
                advertisement={advertisement}
                business={business}
                user={user}
                deleteAdvertisement={deleteAdvertisement}
                updateAdvertisement={async (values) =>
                  await updateAdvertisement(advertisement.id, values)
                }
                isSubmitting={isSubmitting}
                likeAdvertisement={likeAdvertisement}
                unlikeAdvertisement={unlikeAdvertisement}
                commentAdvertisement={commentAdvertisement}
                liked={advertisement.likeData.find(
                  (elem) => elem.sender_id == user.id
                )}
                commentData={advertisement.commentData.sort(function (a, b) {
                  return new Date(b.created) - new Date(a.created);
                })}
                shareAdvertisement={shareAdvertisement}
                deleteComment={deleteComment}
                entity='business'
              />
            ))}
          </div>
        ) : (
          <Empty
            image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
            imageStyle={{
              height: 60,
            }}
            description={<span>This business doesn't have any posts yet.</span>}
            className='pre-content'
          ></Empty>
        )}
      </div>
    </div>
  );
};

export default Advertisements;
