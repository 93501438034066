//** Import Modules */
import { Link } from "react-router-dom";

//** Import Assets */
import logo from "../../assets/images/bizzll-logo-beta-red.png";
import "../../assets/css/Footer.css";

export default function Footer() {
  return (
    <footer>
      <div className="footer-logo">
        <img src={logo} alt="" />
      </div>

      <div className="footer-menu">
        <Link to="/terms-conditions">Terms and Conditions</Link> |
        <Link to="/privacy-policy">Privacy Policy</Link>
      </div>

      <div className="copyright">
        <p>&copy; Copyright BIZZLL 2024. All Rights Reserved.</p>
      </div>
    </footer>
  );
}
