import React, { useEffect, useState } from "react";
import axiosWithAuth from "../../utils/axiosWithAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Form,
  Input,
  InputNumber,
  Divider,
  Modal,
  Button,
  message,
  Steps,
  Drawer,
  Radio,
  Alert,
} from "antd";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import { FE_URL } from "../../common/constants";

export default function ProfileRestaurantsPage(props) {
  const { business } = props;

  const [menuItems, setMenuItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [SelectedMenuItem, setSelectedMenuItem] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [cart, setCart] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [total, setTotal] = useState(0);

  const { Step } = Steps;

  useEffect(() => {
    const APIEndPoint = `/api/restaurant/dish/${business.id}/categ`;
    axiosWithAuth()
      .get(APIEndPoint)
      .then((res) => {
        setMenuItems(res.data);
        console.log("res", res);
        // console.log("menuItems", menuItems);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [business.id]);

  /* Calendar */

  const selectMenuItem = (id) => {
    document
      .querySelectorAll(".single-menuItem-container_select")
      .forEach((element) => element.classList.remove("selected_service"));
    document.getElementById(id).classList.add("selected_service");
    menuItems.map((menuItem) => {
      if (menuItem.dish_id == id) {
        setSelectedMenuItem(menuItem);
        setModalOpen(true);
        console.log("selectMenuItem", SelectedMenuItem);
        console.log("modalOpen", modalOpen);
      }
    });
  };

  const hideModal = (ref) => {
    setModalOpen(false);
  };

  const onFinish = async (values) => {
    next();
    setUserInfo(values);
  };

  const addToCart = async () => {
    console.log("user info", userInfo);
    console.log("business", business);
    console.log("cart", cart);

    const newCart = [...cart];
    let added = false;

    for (let i = 0; i < newCart.length && added == false; i++) {
      if (newCart[i].itemID === SelectedMenuItem.id) {
        newCart[i].itemQuantity = newCart[i].itemQuantity + quantity;
        added = true;
      }
    }

    if (added === false) {
      var cartItem = {
        itemID: SelectedMenuItem.dish_id,
        itemName: SelectedMenuItem.dish_name,
        itemPrice:
          SelectedMenuItem.on_offer === 1
            ? SelectedMenuItem.dish_offer_price
            : SelectedMenuItem.dish_price,
        itemQuantity: quantity,
        businessID: SelectedMenuItem.restaurant_id,
        itemTotal:
          total +
          (SelectedMenuItem.on_offer === 1
            ? SelectedMenuItem.dish_offer_price
            : SelectedMenuItem.dish_price) *
            quantity,
        businessStripeID: business.meta_data.stripe_account_id,
        itemType: "restaurant_product",
        itemDescription: SelectedMenuItem.dish_description,
        startTime: moment().format("YYYY-MM-DD hh:mm:ss"),
      };

      newCart.push(cartItem);
    }

    setCart(newCart);
    // setCurrent(0);
    // showDrawer();
    setOpen(false);
    setTotal(
      total +
        (SelectedMenuItem.on_offer === 1
          ? SelectedMenuItem.dish_offer_price
          : SelectedMenuItem.dish_price) *
          quantity
    );

    message.success("Item Added to Cart!");
  };

  const handleCheckout = async () => {
    // Check to ensure that there are legitimate tickets being called
    if (cart && cart.length > 0) {
      // First, remove any item from localstorage
      localStorage.removeItem("cart");

      // Then, add the new item in the local storage
      window.localStorage.setItem("cart", JSON.stringify(cart));

      // redirect to checkout
      window.location.replace(`${FE_URL}/checkout`);
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  //Radio Button Group
  const [enable, setEnable] = useState(1);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setEnable(e.target.value);
  };

  const steps = [
    {
      content: (
        <div>
          <Form
            // form={form}
            layout="vertical"
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            // autoComplete="off"
            // disabled={isSubmitting}
          >
            {console.log("menuitems", menuItems)}
            <div className="service_container-info">
              <Radio.Group onChange={onChange} value={enable}>
                <Radio value={1}>Delivery</Radio>
                <Radio value={0}>Pickup</Radio>
              </Radio.Group>
              <Divider />
              {enable == 1 && (
                <div>
                  <h3>Your Info</h3>
                  <Form.Item
                    name="firstname"
                    label="Firstname"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your firstname",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your firstname" />
                  </Form.Item>
                  <Form.Item
                    name="lastname"
                    label="Lastname"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your lastname",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your email address" />
                  </Form.Item>
                  <Form.Item
                    name="phone_number"
                    label="Phone Number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your phone number",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your phone number" />
                  </Form.Item>
                  <Form.Item
                    name="address"
                    label="Address"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your address",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your address" />
                  </Form.Item>
                  <Form.Item
                    name="zip_code"
                    label="Zip Code"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your zip code",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your zip code" />
                  </Form.Item>
                  {/* <Form.Item name='agreement' valuePropName='checked'>
                    <Checkbox>
                      By making an order in BIZZLL, you are agreeing to our{" "}
                      <a href='/terms-conditions' target='_blank'>
                        Terms & Conditions
                      </a>
                    </Checkbox>
                  </Form.Item> */}
                </div>
              )}
              <div
                style={{
                  marginTop: 24,
                  float: "right",
                  display: "flex",
                }}
              >
                <Form.Item
                  style={{
                    marginBottom: 0,
                  }}
                >
                  <Button type="primary" htmlType="submit">
                    Next
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      ),
    },
    {
      content: (
        <div>
          <div className="menuItem_container-menuItems">
            {Object.entries(
              menuItems.reduce((groups, item) => {
                if (item.is_available === 1) {
                  if (!groups[item.category]) {
                    groups[item.category] = [];
                  }
                  groups[item.category].push(item);
                }
                return groups;
              }, {})
            ).map(([category, items], index, arr) => (
              <div key={category} className="menu-category">
                {index > 0 && <Divider />}
                {/* Mostrar el título de la categoría */}
                <h3 className="menu-category-title">{category}</h3>

                {/* Renderizar los elementos del menú de esa categoría */}
                {items.map((menuItem, itemIndex) => (
                  <div
                    key={menuItem.dish_id + "_" + itemIndex}
                    className="single-menuItem-container"
                  >
                    <div
                      className="single-menuItem-container_select"
                      id={menuItem.dish_id}
                      onClick={(element) => {
                        selectMenuItem(element.target.id);
                      }}
                    ></div>
                    <div className="single-menuItem-container_image">
                      {console.log('menuItem', menuItem)}
                      <img
                        src={menuItem.dish_image || "/placeholder.jpg"}
                        alt={menuItem.dish_name}
                      />
                    </div>
                    <div className="single-menuItem-container_data">
                      <div className="single-menuItem-container_data-row-1">
                        <label>{menuItem.dish_name}</label>
                      </div>
                      <div className="single-menuItem-container_data-row-2">
                        <div className="single-menuItem-container_data-row-2-price">
                          <label>Price: </label>
                          <label className="single-menuItem-container_data-row-2-price-label">
                            ${" "}
                            {menuItem.on_offer === 1
                              ? menuItem.dish_offer_price
                              : menuItem.dish_price}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div
            style={{
              marginTop: 24,
              float: "right",
            }}
          >
            <Button type="primary" onClick={() => prev()}>
              Previous
            </Button>
          </div>
        </div>
      ),
    },
  ];

  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = async () => {
    setCurrent(current - 1);
  };

  //Change quantity from cart
  const changeHandler = (index, value) => {
    const currentCart = [...cart];
    // setTotal(total - (currentCart[index].itemQuantity * currentCart[index].itemPrice));
    currentCart[index].itemQuantity = value;
    let total_temp = 0;
    currentCart.forEach((element) => {
      console.log("element", element);
      total_temp += element.itemQuantity * element.itemPrice;
    });
    setTotal(total_temp);
    setCart(currentCart);
  };

  //Delete from cart
  const removeHandler = (index) => {
    const currentCart = [...cart];
    setTotal(
      total - currentCart[index].itemQuantity * currentCart[index].itemPrice
    );
    currentCart.splice(index, 1);
    setCart(currentCart);
  };

  return (
    <>
      <FontAwesomeIcon icon={faCartShopping} onClick={showDrawer} />
      <span className="cart_quantity">{cart.length}</span>
      <Drawer
        title={
          <div style={{ width: "100%", display: "flex" }}>
            <p style={{ fontWeight: "bold" }}>CART</p>{" "}
            <p style={{ margin: "0px 10px", fontWeight: "bold" }}>-</p>{" "}
            <p style={{ color: "gray", fontWeight: "bold" }}>
              {cart.length} Item
            </p>
          </div>
        }
        placement="right"
        onClose={onClose}
        visible={open}
        width={500}
      >
        {cart.length > 0 &&
          cart.map((product, index) => {
            return (
              <>
                <div>
                  <div
                    key={product + "_" + index}
                    className="single-menuItem-container-cart"
                  >
                    <div className="single-menuItem-container-cart_image">
                      <img src={SelectedMenuItem.dish_image} alt="Logo" />
                    </div>
                    <div className="single-menuItem-container-cart_data">
                      <div className="single-menuItem-container_data-row-1">
                        <label>{product.itemName}</label>
                      </div>
                      <div className="single-menuItem-container_data-row-2-duration">
                        <label>Quantity: </label>{" "}
                        <label className="single-menuItem-container_data-row-2-duration-label">
                          {product.itemQuantity}
                        </label>
                      </div>
                      <div className="single-menuItem-container_data-row-2-price">
                        <label>Price: </label>
                        <label className="single-menuItem-container_data-row-2-price-label">
                          $ {product.itemPrice}
                        </label>
                      </div>
                    </div>
                    <div className="single-menuItem-container-cart_quantity">
                      <InputNumber
                        min={1}
                        max={999}
                        defaultValue={product.itemQuantity}
                        onChange={(value) => changeHandler(index, value)}
                      />
                    </div>
                    <div
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you wish to delete this item from your cart?"
                          )
                        ) {
                          removeHandler(index);
                        }
                      }}
                      className="single-menuItem-container-cart_delete"
                    >
                      <DeleteOutlined style={{ cursor: "pointer" }} />
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        {cart.length > 0 && (
          <>
            <Divider />
            <div style={{ width: "100%", height: "10%", display: "flex" }}>
              <div style={{ width: "50%", alignSelf: "center" }}>
                <p style={{ float: "left", margin: "0" }}>Total:</p>
              </div>
              <div style={{ width: "50%", alignSelf: "center" }}>
                <p
                  style={{
                    float: "right",
                    fontWeight: "bold",
                    margin: "0",
                  }}
                >
                  $ {total}
                </p>
              </div>
            </div>
            <div style={{ display: "flex", margin: "auto" }}>
              <Button
                onClick={() => {
                  handleCheckout();
                }}
                type="primary"
              >
                Checkout
              </Button>
            </div>
          </>
        )}
        {cart.length == 0 && (
          <p
            style={{
              textAlign: "center",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            You have no items in your cart
          </p>
        )}
        <Button
          onClick={() => {
            window.localStorage.setItem("cart", JSON.stringify(cart));
          }}
        ></Button>
      </Drawer>

      <Modal
        centered
        visible={modalOpen}
        onOk={hideModal}
        onCancel={hideModal}
        footer={null}
        width={1000}
        className="restaurant_modal"
      >
        {SelectedMenuItem != null && (
          <div className="modal_container">
            <div className="modal_image">
              <img src={SelectedMenuItem.dish_image} />
            </div>
            <div className="modal_data">
              <h3>{SelectedMenuItem.dish_name}</h3>
              <Divider />
              <h4>{SelectedMenuItem.dish_description}</h4>
              <Divider />
              <h5>
                {SelectedMenuItem.on_offer === 1
                  ? SelectedMenuItem.dish_offer_price
                  : SelectedMenuItem.dish_price}
                $
              </h5>
              <Divider />
              <InputNumber
                addonBefore="Quantity"
                min={1}
                max={10}
                defaultValue={1}
                onChange={(value) => setQuantity(value)}
              />
              <Divider />
              <Button type="primary" onClick={addToCart}>
                Order
              </Button>
            </div>
          </div>
        )}
      </Modal>

      <div className="menuItem_container">
        <div className="steps-menu">
          <Steps current={current}>
            <Step
              title={
                <span>
                  <FontAwesomeIcon icon="fa-solid fa-id-card" />
                  <label style={{ marginLeft: "5px" }}>Your Info</label>
                </span>
              }
            />
            <Step
              title={
                <span>
                  <FontAwesomeIcon icon="fa-solid fa-list" />
                  <label style={{ marginLeft: "5px", cursor: "pointer" }}>
                    Menu
                  </label>
                </span>
              }
            />
          </Steps>
        </div>

        <div>{steps[current].content}</div>
      </div>
    </>
  );
}
