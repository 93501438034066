import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Input } from "antd";

const CommentAdForm = (props) => {
  const { commentAdvertisement } = props;
  const [form] = Form.useForm();

  return (
    <div className="add-comment">
      <Form
        form={form}
        layout="vertical"
        onFinish={async (values) => {
          await commentAdvertisement(values);
          form.resetFields();
        }}
        onFinishFailed={() => console.log("failed")}
        autoComplete="off"
      >
        <Form.Item
          name="text"
          rules={[
            {
              required: true,
              message: "Please enter text for comment",
            },
          ]}
        >
          <Input
            className="post-form-input"
            id="comment-input"
            suffix={
              <FontAwesomeIcon
                icon="arrow-right"
                htmlType="submit"
                onClick={() => {
                  form.submit();
                }}
              />
            }
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default CommentAdForm;
