import React from "react";
import { Modal } from "antd";
import Comment from "./Comment";

const CommentModal = (props) => {
  const { showModal, setShowModal, commentData, isToday, user, advertisement } =
    props;
  return (
    <div>
      <Modal
        title={`Total ${commentData.length} comments`}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={[]}
      >
        <div className="recent-comments comment-modal">
          {commentData.length
            ? commentData.map((comment) => {
              <Comment
                comment={comment}
                user={user}
                isToday={isToday}
                advertisement={advertisement}
              />
            })
            : "There is no comment on this post"}
        </div>
      </Modal>
    </div>
  );
};

export default CommentModal;
