//** Import Modules */
import React, { useState, useEffect } from "react";
import PageTitleNoAdd from "../../components/header/PageTitleNoAdd";
import axiosWithAuth from "../../utils/axiosWithAuth";
import { useSelector } from "react-redux";
import CheckoutSteps from "./CheckoutSteps";
import { Affix, Tooltip } from "antd";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function CustomerCheckout({ type }) {
  // Get the logged in user data
  const user = useSelector((state) => state.user.user);

  // Set states
  const [cartItems, setCartItems] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [amountTotal, setAmountTotal] = useState(0);
  const [customerInfo, setCustomerInfo] = useState({});

  console.log("customerInfo", customerInfo);
  console.log("cartItems", cartItems);

  // Get URL Info
  const URLQueryVars = new URL(document.location).searchParams;
  const checkoutType = URLQueryVars.get("type");
  const membershipID = URLQueryVars.get("membershipID");
  const couponCode = URLQueryVars.get("cpc");

  // Check what type of product we buying. If it is a membership, use the membership info instead of shopping cart
  useEffect(() => {
    if (checkoutType === "membership") {
      const APIURL = `/api/memberships/membership/${membershipID}`;

      axiosWithAuth()
        .get(APIURL)
        .then((res) => {
          let productItem;
          let getSubTotal;

          if (couponCode === "jJnGdVrb") {
            productItem = {
              itemID: res.data.id,
              itemName: `${res.data.name} - 3mon Trial`,
              itemPrice: res.data.cycle_payment,
              stripeProductID: res.data.stripe_product_id,
              itemCycle: res.data.cycle_period,
              itemInitialPayment: res.data.initial_payment,
            };

            getSubTotal = 0;
          } else {
            productItem = {
              itemID: res.data.id,
              itemName: res.data.name,
              itemPrice: res.data.cycle_payment,
              stripeProductID: res.data.stripe_product_id,
              itemCycle: res.data.cycle_period,
              itemInitialPayment: res.data.initial_payment,
            };

            getSubTotal = productItem.itemPrice;
          }

          setCartItems([productItem]);
          setSubTotal(getSubTotal);
          setAmountTotal(getSubTotal <= 0 ? 0 : getSubTotal);
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {
      // let productItemsList = [];
      // if (checkoutType === "ads") {
      //   const getCookie = (name) => {
      //     const match = document.cookie.match(
      //       new RegExp(`(^| )${name}=([^;]+)`)
      //     );
      //     return match ? JSON.parse(decodeURIComponent(match[2])) : null;
      //   };

      //   const checkoutItem = getCookie("checkoutItem");
      //   productItemsList = [checkoutItem];
      //   console.log("Checkout Data:", checkoutItem);
      // } else {
      const productItems = localStorage.getItem("cart");
      const productItemsList = JSON.parse(productItems);
      // }
      console.log("productItemsList 11111111111111111111", productItemsList);
      const getSubTotal = productItemsList[0].itemTotal;

      // const getSubTotal = productItemsList.reduce(
      //   (prevItem, nextItem) => prevItem.itemTotal + nextItem.itemTotal
      // );

      setCartItems(productItemsList);
      setSubTotal(getSubTotal);

      let fullAmount;
      if (getSubTotal > 0) {
        fullAmount = getSubTotal + getSubTotal * 0.1;
      } else {
        fullAmount = 0;
      }

      setAmountTotal(fullAmount);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutType, membershipID]);

  // Create a Customer on Stripe under BIZZLL's account. If it exists, just get the customer details
  useEffect(() => {
    if (user.id) {
      const APIURL = `/api/stripe/customer/create`;

      const payload = {
        userEmail: user.email,
        userName: `${user.fname} ${user.lname}`,
        referralData: user.referral,
      };

      axiosWithAuth()
        .post(APIURL, payload)
        .then((res) => {
          setCustomerInfo(res.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }, [user]);

  return (
    <div id="checkout-page" className="page-content">
      <PageTitleNoAdd pageTitle="Checkout" />

      <div className="checkout-details page-wrapper">
        <div className="checkout-steps">
          <CheckoutSteps
            user={user}
            customerInfo={customerInfo}
            cartItems={cartItems}
            checkoutType={checkoutType}
            subTotal={subTotal}
            amountTotal={amountTotal}
            couponCode={couponCode}
          />
        </div>

        <Affix offsetTop={100} className="checkout-summary">
          <div className="inner-content">
            <div className="summary-title">Order Summary</div>

            <div className="cart-items-list">
              {cartItems.map((item) => {
                return (
                  <div className="cart-item" key={item.itemName}>
                    <div className="item-name">
                      {item.itemName}
                      {item.itemQuantity && ` x ${item.itemQuantity}`}
                    </div>

                    <div className="item-price">
                      {item.itemPrice <= 0 ? (
                        <>Free</>
                      ) : (
                        <>
                          ${item.itemPrice}
                          {item.itemCycle && <> / {item.itemCycle}</>}
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="cart-subtotal summary-section">
              <div className="summary-row">
                <div className="label">Sub-Total</div>
                <div className="value">${subTotal.toFixed(2)}</div>
              </div>
            </div>

            <div className="extra-fees summary-section">
              {subTotal > 0 && checkoutType !== "membership" && (
                <div className="summary-row">
                  <div className="label">
                    Service Fees{" "}
                    <Tooltip
                      title="We charge service fees for platform use"
                      placement="top"
                      color="#fb4400"
                      key="#fb4400"
                    >
                      <FontAwesomeIcon
                        icon={faQuestionCircle}
                        className="help-icon"
                      />
                    </Tooltip>
                  </div>
                  <div className="value">10%</div>
                </div>
              )}
            </div>

            <div className="cart-total summary-section">
              <div className="summary-row">
                <div className="label">Total</div>
                <div className="value">${amountTotal.toFixed(2)}</div>
              </div>
            </div>
          </div>
        </Affix>
      </div>
    </div>
  );
}
