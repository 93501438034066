// import React, { useEffect, useState } from "react";
// import { Form, Input } from "antd";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { getCookie } from "../../../common/manageCookies";

// const CommentAdForm = (props) => {
//   const { commentAdvertisement, getAllReviewsOfBusiness } = props;
//   const [form] = Form.useForm();
//   // State to determine when a user is logged in
//   const [isLoggedIn, setIsLoggedIn] = useState(false);

//   useEffect(() => {
//     // Logic for checking if have an auth token
//     const getToken = getCookie("ubt");

//     if (getToken) {
//       setIsLoggedIn(true);
//     }
//   }, []);

//   return (
//     <div className="add-comment">
//       <Form
//         form={form}
//         layout="vertical"
//         onFinish={async (values) => {
//           await commentAdvertisement(values);
//           getAllReviewsOfBusiness()
//           form.resetFields();
//         }}
//         onFinishFailed={() => console.log("failed")}
//         autoComplete="off"
//       >
//         <Form.Item
//           name="text"
//           rules={[
//             {
//               required: true,
//               message: "Please enter text for comment",
//             },
//           ]}
//         >
//           <Input
//             className="post-form-input"
//             id="comment-input"
//             placeholder="Type your comment..."
//             suffix={
//               <FontAwesomeIcon
//                 icon="arrow-right"
//                 htmlType="submit"
//                 onClick={() => form.submit()}
//               />
//             }
//             disabled={!isLoggedIn}
//           />
//         </Form.Item>
//       </Form>
//     </div>
//   );
// };

// export default CommentAdForm;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Input, Button } from "antd";

const CommentAdForm = ({ commentAdvertisement }) => {
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      await commentAdvertisement(values); // Ejecuta la función de envío
      form.resetFields(); // Resetea los campos después de un envío exitoso
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  return (
    <div className="add-comment">
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={() => console.log("Submission failed")}
        autoComplete="off"
      >
        <Form.Item
          name="text"
          rules={[
            {
              required: true,
              message: "Please enter text for comment",
            },
          ]}
        >
          {/* <Input
            className="post-form-input"
            id="comment-input"
            placeholder="Type your comment..."
            addonAfter={
              <Button
                type="text"
                icon={<FontAwesomeIcon icon="arrow-right" />}
                onClick={() => form.submit()} // Envía el formulario al hacer clic
              />
            }
          /> */}
                    <Input
            className="post-form-input"
            id="comment-input"
            placeholder="Type your comment..."
            suffix={
              <FontAwesomeIcon
                icon="arrow-right"
                htmlType="submit"
                onClick={() => form.submit()}
              />
            }
            // disabled={!isLoggedIn}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default CommentAdForm;
